<template lang="pug">
div
  page-header(:title='title')
  form(@submit.prevent='gravar')
    .modal-body
      .form-horizontal
        vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
        input-form-text(textInput='Texto', :required='true', v-model='form.texto', :value='form.texto')
    footer-button(:disabled='isActive')
</template>

<script>
import {mapGetters} from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import SelectForm from '@/components/form/SelectForm'
import req from '@/components/request'
import {sucesso, erro} from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    SelectForm,
    VueElementLoading
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  data () {
    return {
      title: 'Atualizações',
      form: {
        texto: ''
      },
      isActive: false
    }
  },
  methods: {
    gravar () {
      const body = this.form
      if (this.$route.params.id) {
        req(`/atualizacoes/${this.$route.params.id}`, 'put', body).then(() => {
          sucesso('Atualizado...')
          this.$router.push('/inicio/atualizacoes')
        }).catch(response => {
          console.log('error: ' + response)
          erro()
        })
      } else {
        req('/atualizacoes', 'post', body).then(() => {
          sucesso('Atualizado...')
          this.$router.push('/inicio/atualizacoes')
        }).catch(response => {
          console.log('error: ' + response)
          erro()
        })
      }
    },
    getDados () {
      req(`/atualizacoes/${this.$route.params.id}`).then(resp => {
        this.form.texto = resp.texto
        this.isActive = false
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    verifPermissao () {
      this.$router.push('/erro')
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
    if (this.user.id !== 1) {
      this.verifPermissao()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
